<template>
    <!-- Under maintenance-->
    <div class="">
      
  
      <div class=" p-2 p-sm-3" >
        <h3 class="text-danger"></h3>
        
        <v-select
            v-model="selected"
            placeholder="select dashboard" 
            label="param_name"
            :options="dashboardList"
        >
        <h4>Select Dashboard: {{ selected }} </h4>
        </v-select>
        <div v-if="selected">
            <iframe  class="mt-2" :title="selected.param_name" width="100%" height="800px" :src="selected.title" frameborder="0" allowFullScreen="true"></iframe>
        </div>
      </div>
    </div>
  <!-- / Under maintenance-->
  </template>
  
  <script>
  /* eslint-disable global-require */
  import {
    BLink, BFormInput, BButton, BForm, BImg,
  } from 'bootstrap-vue'
  import SattvaLogo from '@core/layouts/components/Logo.vue'
  import store from '@/store/index'
  import Ripple from "vue-ripple-directive";
  import vSelect from 'vue-select'
  import AdminConfigService from '@/services/admin-config-service'
  
  export default {
    components: {
      BLink,
      BFormInput,
      BButton,
      BForm,
      BImg,
      SattvaLogo,
      vSelect,
    },
    data() {
      return {
       dashboardList: [],
       selected: null,
       role: null,
       empId: null,
      }
    },
    computed: {
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg')
          return this.downImg
        }
        return this.downImg
      },
    },
    created() {
        const userData = JSON.parse(localStorage.getItem('userData'))
        this.role = userData.roles[0]
        this.empId = userData.emp_id
        const module = 'power bi'
        // const data = {
        //     module: 'power bi',
        //     role: this.role,
        //     empId: this.empId
        // }
        AdminConfigService.getConfigPowerBI(module, this.role, this.empId).then(res => {
            console.log('power bi res: ', res)
            this.dashboardList = res.data.data
        })
    },
    mounted() {
        
    },
    methods: {
      // openPolicyDoc() {
      //   // window.open("https://ats.sattvahuman.in/assets/documents/Leave policy 2024.pdf")
      //   window.open("http://localhost:8080/assets/documents/Leave policy 2024.pdf")
      // }
      
    }
  }
  </script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  //@import '@core/scss/vue/pages/page-misc.scss';


//   .visual .date-slicer .date-slicer-head.wrap .date-slicer-range {
//     flex-wrap: nowrap; 
//   }
.shareFlyoutActions[_ngcontent-ng-c485176529] {
    margin-left: 20px;
    display: none;
}
.glyphicon-share-facebook {
    display: none;
}
.glyphicon-share-twitter {
    display: none;
}
.glyphicon-share-linkedIn {
    display: none;
}
.navigation-wrapper[_ngcontent-ng-c485176529] .socialIcon[_ngcontent-ng-c485176529] {
    display: none;
}
span.navigation-wrapper {
    display: none !important;
}
logo-bar-social-sharing {
    display: none !important;
}
</style>
  